/* Este estilo define el color del Stepper y su contenido */
.Steep {
    color: #e84e0e !important; /* Color del texto del Step activo */
    margin-bottom: 3vh;
  }

  .Steep .MuiStepIcon-root:not(.Mui-active) {
    /* Estilos para los Step que no están activos */
    /* color: #333; Cambia el color del indicador del Step no activo */
    font-size: 2.5vh; /* Cambia el tamaño de la fuente del Step no activo */
  }

  .Steep .MuiStepLabel-label:not(.Mui-active) {
    /* Estilos para el StepLabel que no está activo */
    /* color: #333; Cambia el color del texto del StepLabel no activo */
    font-size: 1.5vh; /* Cambia el tamaño de la fuente del StepLabel no activo */
  }
  
/* Este estilo define el color del indicador del Step activo */
.Steep .MuiStepIcon-root.Mui-active {
    color: #e84e0e !important; /* Color del indicador del Step activo */
    /* border: solid; */
    font-size: 2.5vh;
  }
.Steep .MuiStepLabel-label.Mui-active {
  /* Estilos para el StepLabel que no está activo */
  /* color: #333; Cambia el color del texto del StepLabel no activo */
  font-size: 1.5vh; /* Cambia el tamaño de la fuente del StepLabel no activo */
}

  /* Este estilo define el color del indicador del Step completado */
.Steep .MuiStepIcon-root.Mui-completed {
    color: #e84e0e !important; /* Color del indicador del Step activo */
    font-size: 2.5vh;
  
  }

  .Steep .MuiStepLabel-label.Mui-completed {
    /* Estilos para el StepLabel que no está activo */
    /* color: #333; Cambia el color del texto del StepLabel no activo */
    font-size: 1.5vh; /* Cambia el tamaño de la fuente del StepLabel no activo */
  }
  
  /* Estilo para la línea entre los Step */
.Steep .MuiStepConnector-line {
  width: 3.5vw; /* Define el ancho de la línea entre los Step en unidades de vista */
}

.button-atras{
  font-size: 2vh !important;
  width: 8vw!important;
  height:4.5vh!important;
  margin-left: 7vw  !important;
  }

.button-siguiente{
  /* color: #e84e0e !important; */
  /* background-color: #e84e0e !important;  */
  font-size: 2vh !important;
  width: 8vw!important;
  height:4.5vh!important;

}
  