:root {
    --background-calendar: rgba(40, 40, 40);
    /* #615e5a; */
    --numbers-calendar: rgba(200, 200, 200);
    /* #1d2429; */
    --numbers-calendar-passive: rgba(200, 200, 200, 0.2)
}

.rdrCalendarWrapper {
    box-sizing: border-box;
    background: var(--background-calendar);
    /* #ffffff; */
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDateDisplay {
    display: flex;
    justify-content: space-between;
}

.rdrDateDisplayItem {
    flex: 1 1;
    width: 0;
    text-align: center;
    color: inherit;
}

.rdrDateDisplayItem+.rdrDateDisplayItem {
    margin-left: 0.833em;
}

.rdrDateDisplayItem input {
    text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
    cursor: default;
}

.rdrDateDisplayItemActive {}

.rdrMonthAndYearWrapper {
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
}

.rdrMonthAndYearPickers {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rdrMonthPicker {}

.rdrYearPicker {}

.rdrNextPrevButton {
    box-sizing: inherit;
    cursor: pointer;
    outline: none;
}

.rdrPprevButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rdrNextButton {
    padding-left: 0.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rdrMonths {
    display: flex;
}

.rdrMonthsVertical {
    flex-direction: column;
}

.rdrMonthsHorizontal>div>div>div {
    display: flex;
    flex-direction: row;
}

.rdrWeekDays {
    display: flex;
}

.rdrWeekDay {
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
}

.rdrDays {
    display: flex;
    flex-wrap: wrap;
}

.rdrDateDisplayWrapper {}

.rdrMonthName {}

.rdrInfiniteMonths {
    overflow: auto;
}

.rdrDateRangeWrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    margin-left: -1vw;
}

.rdrDateInput {
    position: relative;
}

.rdrDateInput input {
    outline: none;
}

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
}

.rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
}

.rdrDayNumber {
    display: block;
    position: relative;
}

.rdrDayNumber span {
    color: var(--numbers-calendar);
}

.rdrDayPassive .rdrDayNumber span {
    color: var(--numbers-calendar-passive) !important;
}

.rdrDayDisabled {
    cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
    .rdrDay {
        flex-basis: 14.285% !important;
    }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    pointer-events: none;
}

.rdrInRange {}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    pointer-events: none;
}

.rdrDayHovered {}

.rdrDayActive {}

.rdrDateRangePickerWrapper {
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDefinedRangesWrapper {
    border-radius: 1vh;
}

.rdrStaticRanges {
    /* margin-top: 10vh; */
    display: flex;
    flex-direction: column;
}

.rdrStaticRange {
    font-size: inherit;
    border-radius: 1vh;
    height: 4vh;
}

.rdrStaticRangeLabel {}

.rdrInputRanges {}

.rdrInputRange {
    display: flex;
}

.rdrInputRangeInput {
    margin-left: -0.7vw;
    font-size: 1.2vh
}