.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #ff0000; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mySwiper::-webkit-scrollbar {
    width: 12px;
  }
  
  .mySwiper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .mySwiper::-webkit-scrollbar-thumb {
    background-color: #ff0000;
    border-radius: 10px;
  }
  
  .mySwiper::-webkit-scrollbar-thumb:hover {
    background: #eb0000;
  }

  .swiper-scrollbar swiper-scrollbar-horizontal{
    background-color: #eb0000 !important;
  }