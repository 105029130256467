/* registro.css */
 /* estilos container */
 .rootRegister{
    flex: '1 1 auto' !important;
    align-items: 'center' !important;
    display: 'flex' !important;
    justify-content: 'center' !important;
    max-width: 550 !important;
    width: '100%' !important;
    margin-top: 5vh;

 }
/* Estilos para el título "Registro" */
.h4 {
    color: #ffffff; /* Cambia el color del título a azul */
    margin-bottom: 2vh !important;
  }
  /* Estilos para el subtitulo */
  .Typo{
    margin-bottom: 2vh !important;
  }
  