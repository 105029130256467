.bomba-common-status{
    min-width:  13vw;
    max-width:  13vw; 
    height: 14.5vh;
    border-radius: 1vh; 
    z-index: 6;
    /* margin-top: 0.5vh;  */
    /* margin-left: 0.5vw;  */
    /* margin-bottom: -0.1vh; */
    border: solid 0.2vh rgb(223, 13, 0); 
    background-color: #160b0b 
}