.custom-card {
  width: 20vw;
  height:20vh;
  position: relative;
  overflow: visible;
  justify-content: center;
  align-items: center;
}

.card-content-top {
  position: absolute;
  top: -1vh;
  width: 18vw;
  background-color: blue;
  color: white;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-content-top:hover {
  transform: translateY(-3vh);
}

.card-content-top:hover + .extra-content {
  display: block;
}

.extra-content {
  display: none;
  position: absolute;
  top: 2vh;
  width: 100%;
  background-color: white;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.card-content-bottom {
  padding-top: 8vh; /* Ajustar el padding-top para evitar que el contenido se superponga */
}