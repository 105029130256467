/* Estilo del track de la scrollbar */
::-webkit-scrollbar {
    width: 12px; /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-track {
    background: #5b3d30; /* Color del fondo del track */
    border-radius :1vh;
}

::-webkit-scrollbar-thumb {
    background-color: #e84e0e; /* Color de la barra de desplazamiento */
    border-radius: 6px; /* Radio de borde para hacerlo redondeado */
    border: 3px solid #333; /* Espacio alrededor del thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #e84e0e; /* Color cuando se pasa el mouse por encima */
}

/* e84e0e color del scroll  5b3d30 color barra*/