/* Tabla.css */
.container-Tabla {
    justify-content: center;
    align-content: start;
    height: 70vh;
  }
  
  .header-container-tabla {
    justify-content: center;
    height: 5vh;
  }
  
  .header-item-tabla {
    justify-content: center;
    align-content: center;
    font-size: 0.85vw;
    /* font-family:monospace; */
    height: 5vh;
    /* border: solid 0.1vh #000; */
    color: white;
    background-color: #504f4f;
  }
  
  .row-container-tabla {
    justify-content: center;
    align-content: start;
    height: 65vh;
    /* border: solid 0.1vh #000; */

  }
  
  .row-item {
    border-bottom: 0.1vh solid #000000;
    /* border: solid 0.1vh #504f4f; */
    justify-content: center;
    text-align: center;
    align-content: center;
    font-size: 0.75vw;
    height: 5.4vh;
    overflow: hidden;
  }
  
  .button-tabla {
    font-size: 0.75vw !important;
    height: 3vh;
    min-width: 4vw !important;
    max-width: 4vw !important;

  }
  .button-tabla-tags {
    font-size: 0.75vw !important;
    height: 3vh;
    min-width: 6vw !important;
    max-width: 6vw !important;

  }
  
  .icon {
    color: white;
    font-size: 1vw;
    margin-left: 0.2vw;
  }
  