/* SensorLoader.css */
.sensor-loader {
    text-align: center;
    padding: 20px;
  }
  
  .sensor-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .sensor {
    background-color: #f0f0f0;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    width: 200px;
    text-align: center;
  }


html,body {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.wave {
    position: relative;
    margin-top: 5vh;
    -webkit-box-reflect: below -3.5vh  
    linear-gradient(
      transparent,
      rgba(255, 251, 251, 0.363)
    );
    
  }

.wave span {
  color: white;
  position: relative;
  display: inline-block;
  font-size: 3.5vh;
  text-transform: uppercase;
  animation: wavy 2s infinite;
  animation-delay: calc(.1s * var(--i));
}

@keyframes wavy {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  20%{
    transform: translateY(-10px);
  }
}


.waveFalla {
    position: relative;
    margin-top: 5vh;
    -webkit-box-reflect: below -20px 
    linear-gradient(
      transparent,
      rgba(255, 251, 251, 0.363)
    );
  }

.waveFalla span {
  color: rgba(153, 2, 2, 0.952);
  position: relative;
  display: inline-block;
  font-size: 3.5vh;
  /* top:4vh; */
  text-transform: uppercase;
  /* animation: wavy 2s infinite; */
  animation-delay: calc(.1s * var(--i));
}

@keyframes wavy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20%{
    transform: translateY(-10px);
  }
}
