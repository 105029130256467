
.circle-container {
    min-width: 2.5vw !important;
    max-width: 2.5vw !important;
    height: 5vh;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Puedes cambiar el color de fondo si lo deseas */
  }
  
  .circle-container canvas {
    border-radius: 50%;
  }
  