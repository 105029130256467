.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: #e84e0e;
    border-radius: var(--swiper-scrollbar-border-radius, 10px);
    left: 0;
    top: 0;
}
.swiper-scrollbar {
    border-radius: var(--swiper-scrollbar-border-radius, 10px);
    position: relative;
    touch-action: none;
    background: rgb(232, 78, 14,0.2);
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    font-size: 18px;
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* padding: 30px; */
  }


  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 4* 2);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff0000;
}
  

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ff0000;
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: #4f46e5;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
}