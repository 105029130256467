.root {
    /* background-color: #ffffff;  */
    flex: 1 1 auto !important;
    align-items: center !important;
    /* display: flex !important; */
    justify-content: center !important;
    /* max-width: 65vw !important; Reemplaza 'theme.spacing(3)' con el valor deseado en píxeles */
    /* padding: 12vh !important; Reemplaza 'theme.spacing(3)' con el valor deseado en píxeles */
    height: 100% !important;
    /* background-color: black; */
  }
  
  .paper {
    width: 60vw !important;
    height: 65vh !important;
    margin-top: 8vh !important;
    /* border: solid 0.01vh !important; */
    /* background-color: transparent !important; */
    background-color: #1d1e1fec !important;
    border-radius: 3vh;
  }
  
  .title {
    font-size: 5vh !important ;
    /* font-weight: bold !important; */
    margin-bottom: 1vh !important;
    margin-top: 3vh !important;
    /* color: #e84e0e !important; */
    color: #dad9d9 !important;
    font-weight: 300;

  }

  .textField {
    width:2vw !important;
    margin-left:2.5vw !important;
  }
  
  .subtitle {
    color: #7d7d7d !important; /* Reemplaza 'theme.palette.text.secondary' con el color deseado */
    margin-bottom: 16px !important; /* Reemplaza 'theme.spacing(2)' con el valor deseado en píxeles */
  }
  
  .buttonContainer {
    display: flex !important;
    justify-content: center !important;
  }
  
  .button {
    margin: 8px !important; /* Reemplaza 'theme.spacing(1)' con el valor deseado en píxeles */
    color: #ffffff !important; /* b7472a Reemplaza 'theme.palette.info.main' con el color deseado */
    /* color: #2196f3 !important; b7472a Reemplaza 'theme.palette.info.main' con el color deseado */
    /* border:solid !important; */
    height: 3.5vh !important ;
    max-width: 10vw!important;
    font-size: 1.5vh !important;
  }
  
  .form {
    width: 100% !important;
    height:20vh;
    text-align: center;
    /* border: solid; */
  }
  
  .submitButton {
    margin-top: 24px !important; /* Reemplaza 'theme.spacing(3)' con el valor deseado en píxeles */
  }


  .image-container {
    width: auto; 
    height: auto;
    max-height: 80vh;
    max-width: 100%; 
    /* object-fit: contain; */
    /* position: relative; */
    margin-top: -7vh;
    margin-left: 4vw;

  }
  
  /* .rotating-image {
    max-height: 80vh!important;
    margin-top: -1vh;
    position: absolute;
    top: 0;
    left: 0;
    width: auto; 
    height: auto;
    max-height: 80vh !important;
    max-width: 100%; 
    object-fit: contain;
    position: relative;
    /* animation: rotateBackground 10s linear infinite; */
  /* }} */
/*   
  @keyframes rotateBackground {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  
  