:root {
    --rdrCalendarWrapper: rgb(143, 41, 41); /* no hace nada?; */ 
    --rdrDateDisplayWrapper: rgb(41, 40, 40);/* #1d2429; */

    --LetraWeekDay : rgb(220, 220, 220) ;

    /* Botones Superiores */
    --LetraMesesBotonesSuperiores: rgb(225, 231, 240);
    --NextPrevButton:rgb(247, 239, 247,0);
    --BotonesSupFondo: rgb(60, 60, 60);

    --NextPrevButtonHover: rgb(234, 94, 36, 0.2);
    /* Menu Izquierda */
    --MenuLateralIzqFondo:rgb(40, 40, 40); /* rgb(97, 94, 90); */
    --MenuLateralIzqFondoLetras: rgb(40, 40, 40); /* rgb(97, 94, 90); */
    --MenuLateralIzqFondoLetrasHover: rgb(60, 60, 60);
    --MenuLateralIzqBarraSeparadora: rgb(20, 20, 20);
    --MenuLateralIzqColorLetras: rgb(180, 180, 180) ;
    /* --MenuLateralIzqColorLetrasHover: rgb(252, 8, 8) ;  */
    
    /* Color dias seleccionados */
    --SelectedDayBackgroundColor: rgba(234, 94, 36, 0.2) ; /* rgb(61, 145, 255) */
    --SelectedDayColor: rgba(230, 230, 230, 1) ;/* rgba(51, 50, 50, 0.9) */

    
  }

.rdrCalendarWrapper{
    box-sizing: border-box;
    /* border-top-left-radius: 2vh; */
    width: 47vw;
    /* background-color: #00eaff; */
    font-size: 12px;
    display: inline-flex;
    flex-direction: row;
  }

  .rdrDateDisplayWrapper {
    background-color: var(--rdrDateDisplayWrapper)/* rgb(246, 247, 239); */;
    border-radius: 2vh;
    height: 1vh;
}
  
  /* Fondo detras botones superiores */
  .rdrDateDisplayWrapper{
    background-color: var(--rdrDateDisplayWrapper)/* rgb(246, 247, 239); */
  }
  
  .rdrDateDisplay {
    /* margin: 1.833em; */
    width: 20vw;
    height: 3vh;
    margin-left: 1vw;
    margin-top: 2vh;
}
  
  /* Color fondo botones superiores */
  .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: var(--BotonesSupFondo);/* rgb(255, 255, 255); */
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
  }
  
  /* Letra meses en botones superiores */
  .rdrDateDisplayItem input{
      cursor: pointer;
      height: 4.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: var(--LetraMesesBotonesSuperiores);/* #849095; */
      font-size: 1.5vh;
      margin-top: -2vh;
      margin-left: -1.1vw;
    }

    .rdrDateDisplayItem {
      border-radius: 4vh;
      background-color: var(--BotonesSupFondo);
      box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
      border: 1px solid transparent;
  }
  
  .rdrDateDisplayItemActive{
    /* border-color: currentColor; */
    border-color: #e84e0e;

  }
  
  /* Color letra meses en botones superiores */
  .rdrDateDisplayItemActive input{
      color: var(--LetraMesesBotonesSuperiores);/* #7d888d */
    }
  
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 40px;
    padding-top: 10px;
  }
  
  .rdrMonthAndYearPickers{
    font-weight: 600;
  }
  
  /* Letra Mes/Año Superior */
  .rdrMonthAndYearPickers select{
      -moz-appearance: none;
           appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 10px 30px 10px 10px;
      border-radius: 4px;
      outline: 0;
      color: var(--LetraMesesBotonesSuperiores);/* #3e484f; */
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      background-color: var(--MenuLateralIzqFondoLetras);
      cursor: pointer;
      text-align: center;
      font-size: 1.4vh;
      font-weight: 500;
    }
  
  .rdrMonthAndYearPickers select:hover{
        background-color: var(--MenuLateralIzqFondoLetras);
      }
  
  .rdrMonthPicker, .rdrYearPicker{
    /* margin-top: 10vh; */
    /* border: solid 0.1vh */
  }
  
  /* Botones */
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: var(--NextPrevButton);
    
  }
  
  .rdrNextPrevButton:hover{
      background: var(--NextPrevButtonHover);
    }
  
  .rdrNextPrevButton i {
      display: block;
      width: 0vw;
      height: 1.2vh;
      text-align: center;
      border-style: solid;
      /* margin: auto; */
      transform: translate(-3px, 0px);
      
    }
  
  .rdrPprevButton i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent rgb(234, 94, 36) transparent transparent;
      transform: translate(-3px, 0px);
      margin-left: 1vw;

    }
  
  .rdrNextButton i {
      margin: 0 0 0 7px;
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent rgb(234, 94, 36);
      transform: translate(3px, 0px);
      margin-left: -1vw;

    }
  
  .rdrWeekDays {
    padding: 0 0.833em;
  }
  
  /* Letra mes seleccionado */
  .rdrMonth{
    /* padding: 0 0.833em 1.666em 0.833em; */
    font-size: 1.4vh;
    width: 0.667vw;
    margin-left: 0.3vw;
    /* border:solid */
  }


  
  .rdrMonth .rdrWeekDays {
      padding: 0;
    }
  
  .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
  }
  
  /* Letra dias de la semana */
  .rdrWeekDay {
    font-weight: 100;
    line-height: 2.8em;
    color: var(--LetraWeekDay) ; /* rgb(132, 144, 149) */
    font-size: 1.1vh;
  }
  
  .rdrDay {
    background: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0;
    padding-bottom: 0.6vh;
    line-height: 3.000em;
    height: 4vh;
    text-align: center;
    /* color: #1d2429; */
    /* border:solid */

  }

  .rdrStaticRange {
    width: 100%;
    height: 100%;
    border-radius: 7vh;
  }
  
  .rdrDay:focus {
      outline: 0;
    }
  
    /* Letras Dias */
  /* .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vh; 
  } */
    /* Letra Hoy */
  .rdrDayToday .rdrDayNumber span{
    font-weight: 500;
    font-size: 1vh;
  }
  
  /* Marcador Hoy */
  .rdrDayToday .rdrDayNumber span:after{
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1.1vh;/* 18px; */
      height: 0.2vh;/* 2px; */
      border-radius: 1vh;
      background: #e84e0e;
      margin-bottom: -0.5vh;
    }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
        background: #e84e0e;
      }
  
      /* Letras de dias seleccionados */
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
            color: var(--SelectedDayColor) ;
            font-size: 1.14vh;
          }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: #e84e0e;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    background-color:var(--SelectedDayBackgroundColor);
    /* border: solid 0.01vh #e84e0e; */
    
  }

  /* Estilos para el rango de fechas seleccionado */
.react-date-range__selected,
.react-date-range__selected *,
.react-date-range__preview {
    border: 1vh solid #f7f7f7; /* Borde del rango seleccionado */
}

  
  .rdrSelected{
    left: 2px;
    right: 2px;
    background-color:var(--SelectedDayBackgroundColor)
  }
  
  .rdrInRange{
    background-color:var(--SelectedDayBackgroundColor);

  }
  
  .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
    background-color:var(--SelectedDayBackgroundColor);
  }
  
  .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
  
  .rdrSelected{
    border-radius: 1.042em;    
  }
  
  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;

    }
  
  .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }
  
  .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
      left: 0px;

    }
  
  .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
     border-top-right-radius: 1.333em;
     border-bottom-right-radius: 1.333em;
     border-right-width: 1px;
    right: 0px; 
   }
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(234, 94, 36, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid #e84e0e;
    z-index: 1;

  }
  
  .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }
  
  .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }
  
  /* Background selector de dia mediante texto - tamaño de letra */
  .rdrDefinedRangesWrapper{
    font-size: 12vh;
    width: 8vw;
    border-right: solid 1px rgb(220, 220, 220,0);
    /* background: var(--MenuLateralIzqFondo); */
    background: transparent !important;
    font-size: 1.2vh;
    color: var(--MenuLateralIzqColorLetras);
    height: 0vh;
  }
  
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
      color: #e84e0e;
      font-weight: 600;
      
    }
/* botones laterales */
/* .rdrStaticRanges {
  background-color: #282828;
  display: flex;
  flex-direction: row !important; 
  border-radius: 1vh;
  border: solid 2px #eff2f7;
  width: 35vw;
  min-height: 5vh;
  max-height: 5vh;
  margin-bottom: 0vh;
  margin-top: -5vh;
  margin-left: 7vw;
  align-content: center;
} */
.rdrStaticRanges {
  background-color: #282828;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centrar horizontalmente los elementos hijos */
  align-items: center; /* Centrar verticalmente los elementos hijos */
  border-radius: 1vh;
  /* border: solid 2px #eff2f7; */
  width: 35vw;
  /* margin-top: 0vh !important; */
  min-height: 5vh;
  max-height: 5vh;
  /* margin: -5vh 0 0 7vw; Combinación de márgenes */
}

.rdrStaticRangeLabel{
  align-content: center;
  width: 7vw;
}


  
    /* Background selector de dia con rango predeterminado */
    .rdrStaticRange {
      height: 2vh !important;
      /* border: solid; */
      cursor: pointer;
      display: block;
      outline: 0;
      /* border-bottom: 1px solid var(--MenuLateralIzqBarraSeparadora) #eff2f7; */
      padding: 0;
      margin-top: 0.5vh;
      background: var(--MenuLateralIzqFondoLetras);/*  #fff */
      color: var(--MenuLateralIzqColorLetras);
    }

    /* span.rdrStaticRangeLabel{
      color: #f5f5f5 !important;
      font-size: 1.2vh;
      align-content: center;
      text-align: center;
      position: absolute;
      min-height: 2.9vh !important;
      max-height: 2.9vh !important;
    } */
    span.rdrStaticRangeLabel {
      display: flex;
      justify-content: center; /* Centrar horizontalmente el texto */
      align-items: center; /* Centrar verticalmente el texto */
      width: 7vw;
      min-height: 2.9vh; /* Fijar la altura */
      max-height: 2.9vh; /* Fijar la altura */
      color: #f5f5f5 !important;
      font-size: 0.6vw;
      text-align: center;
      position: relative; /* Cambiar a relative si no necesitas absolute */
      /* border: solid 2px #eff2f7; */
    }
  
  .rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
        background: var(--MenuLateralIzqFondoLetrasHover);
      }
  
  /* .rdrStaticRangeLabel{
    display: flex;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: center;
    font-size: 1.5vh;
  } */

  .rdrInputRangeInput {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    /* border: solid 1px rgb(222, 231, 235); */
    margin-left: -1.5vw;
    color: #ffffff;
    font-size: 1vh;
    background-color: #d5dce0;

}
  
  
  .rdrInputRanges{
    padding: 10px 0;
  }
  
  .rdrInputRange{
    align-items: center;
    padding: 7px 20px;
    /* margin-top: 20vh; */
    margin-left: 12vw ;
    /* border: solid; */
    z-index: 3;

    min-width: 17.9vw;
    max-width: 17.9vw;
    font-size: 0.75vw;
  }
  
  .rdrInputRangeInput{
    border: solid;
    min-width: 2vw;
    max-width: 2vw;
    min-height: 3vh;
    max-height: 3vh;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(0, 0, 0);
    color: rgb(29, 30, 31);/* rgb(108, 118, 122); */
    font-size: 0.5vw;
    margin-right: 0.5vw;
  }
  
  .rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
      border-color: rgb(180, 191, 196);
      outline: 0;
      color: #3a3434;
    }
  
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    /* border: 1px solid currentColor; */
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: transparent;
  }
  
  .rdrDayPassive{
    pointer-events: none;
  }
  
  .rdrDayPassive .rdrDayNumber span{
      color: #d5dce0;
    }
  
  .rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
      display: none;
    }
  
  .rdrDayDisabled {
    background-color: rgb(248, 248, 248);
  }
  
  .rdrDayDisabled .rdrDayNumber span{
      color: #aeb9bf;
    }
  
  .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  
  .rdrMonthName{
    text-align: left;
    font-weight: 600;
    /* color: #849095;
    padding: 0.833em; */
    color: #deebf0;/* #849095; */
    padding: 1em;/* 0.833em; */
    
  }
  